/* This stylesheet generated by Transfonter (https://transfonter.org) on August 20, 2017 6:55 PM */

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
